import Mock from "../mock";
import { getContactInfo, getExperienceInfo, getInformation, getSkillsInfo, getSocialLinks, getProjectStructure, getPortfolioData} from "./firestore.js"

const ImageUrls = {
  Images: {
    brandImage:   'images/brand-image.png',
    aboutImage:   'images/about-image.jpg',
    aboutImageLg: 'images/about-image-lg.jpg',
    aboutImagePlaceHolder: 'images/about-image-placeholder.png',
    portfolioPlaceHolder: 'images/portfolio-image-placeholder.png',
  },
}

// Firebase Connections
Mock.onGet("/api/contactinfo").reply(async _ => {
  const response = await getContactInfo();
  return [200, response];
});
Mock.onGet("/api/information").reply(async _ => {
  const response = await getInformation()
  return [200, response];
});

Mock.onGet("/api/skills").reply(async _ => {
  console.log(`BEFORE FETCHING `)
  const response = await getSkillsInfo();
  return [200, response];
});

Mock.onGet("/api/experience").reply(async _ => {
  const response = await getExperienceInfo();
  return [200, response];
});

Mock.onGet("/api/experience").reply(async _ => {
  const response = await getExperienceInfo();
  return [200, response];
});

Mock.onGet("/api/socialLinks").reply(async _ => {
  const response = await getSocialLinks();
  return [200, response];
})

Mock.onGet("/api/images").reply(_ => {
  const response = ImageUrls;
  return [200, response];
})

Mock.onGet("/api/projectsData").reply(async _ => {
  const response = await getPortfolioData();
  return [200, response];
})

// tyleraldrich.dev/projects?projectId={project-name}
Mock.onGet(`/api/projects`).reply(async config => {
  const url = new URL(config.url);
  const projectId = url.searchParams.get('projectId');

  const response = getProjectStructure(projectId);
  return [200, response]
})

Mock.onGet('/api/projectFile').reply(async config => {
  const url = new URL(config.url);
  const projectId = url.searchParams.get('projectId');
  const filePath = url.searchParams.get('filePath');

  const response = getProjectFileContents(projectId, filePath);
  return [200, response];
})

